<template>
  <v-card
      :class="acao.cor === 'primary' ? 'card-primary' : 'card-secondary'"
      :color="acao.cor"
      :href="acao.url"
      class="pa-2 card-acoes text-center"
      height="180"
      hover
      rounded
      style="color: white !important; text-decoration: none !important;"
      target="_blank"
      variant="elevated"
      width="180">
    <v-card-title>
      <div>
        <NuxtImg
            loading="lazy"
            :alt="`Clique para ${acao.titulo}`"
            :src="acao.imagem"
            height="71"
            placeholder>
        </NuxtImg>
      </div>
    </v-card-title>
    <v-card-text class="my-2">
      <p class="font-weight-bold">
        {{ acao.titulo }}
      </p>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import type {PropType} from "vue";
import type {Acao} from "~/pages/home.vue";

export default defineComponent({
  props: {
    acao: {
      type: Object as PropType<Acao>,
      required: true
    }
  }
})
</script>
<style scoped>
.card-acoes {
  border-radius: 10px !important;
  background-color: transparent !important;
}

.card-primary {
  background-image: linear-gradient(180deg, rgb(var(--v-theme-primary-lighten-1)) 0%, rgb(var(--v-theme-primary-darken-3)) 100%);
}

.card-secondary {
  background-image: linear-gradient(180deg, rgb(var(--v-theme-secondary-lighten-1)) 0%, rgb(var(--v-theme-secondary-darken-3)) 100%)
}
</style>
